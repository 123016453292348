import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AbstractComponent, Channel, ChannelService, Department, DepartmentService, ExportService, ReportService, User, UserService } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged } from 'rxjs';

interface ResponseCountItemsContactTypes {
  totalContactsCurrent: number;
  totalContactsPrevious: number;
  totalContacts: number;
  totalChannels: number;
  totalDepartments: number;
  totalUsers: number;
}

@Component({
  selector: 'app-report-list-contact',
  templateUrl: './report-list-contact.component.html',
  styleUrl: './report-list-contact.component.scss'
})
export class ReportListContactComponent extends AbstractComponent implements OnInit{
  title: string;
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;
  reportForm: any;
  userAttendant: User[];
  department: Department[];
  channelAttendant: Channel[];
  countItemsContact: ResponseCountItemsContactTypes;

  constructor(
    injetor: Injector,
    private fb: FormBuilder,
    public userSerivce: UserService,
    public departmentService: DepartmentService,
    public channelService: ChannelService,
    public reportService: ReportService,
    public exportService: ExportService,
    public override router: Router,
    public dialog: MatDialog,
  ) {
    super(injetor);
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.createForm();
    this.getByUserAttendant();
    this.getDepartmentByCompany();
    this.getByAllChannel();
    this.initializeData();

    this.route.queryParams.subscribe(params => {
      this.title = params['title'];
    });

    this.reportForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(val => {
      this.onFormChange(val);
    });
  }

  private initializeData() {
    const formsValue = {
      dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
      dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
      dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
      dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
      department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
      attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
      channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel
    };
    this.getCountContactItems(formsValue);
  }
  private createForm(): void {
    const today = new Date();
    const yesterday = new Date(today);
    const todayDay = today.getDate();
    const todayMonth = today.getMonth();
    const todayYear = today.getFullYear();

    const lastMonthSameDay = new Date(todayYear, todayMonth - 1, todayDay);
    const lastMonthPreviousDay = new Date(todayYear, todayMonth - 1, todayDay-1);

    yesterday.setDate(today.getDate() - 1);
    this.reportForm = this.fb.group({
      dateRange: this.fb.group({
        dateStartCurrent: [yesterday, []],
        dateEndCurrent: [today, []],
        dateStartPrevious: [lastMonthPreviousDay, []],
        dateEndPrevious: [lastMonthSameDay, []],
      }),
      department: [-1],
      attendant: [-1],
      channel: [-1]
    });
  }

  onFormChange(changes: any) {
    if (changes.dateRange.dateStartCurrent && changes.dateRange.dateEndCurrent ||
      changes.dateRange.dateStartPrevious && changes.dateRange.dateEndPrevious) {
      const formsValue = {
        dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent,
        dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent,
        dateStartPrevious: this.reportForm.value.dateRange.dateStartPrevious,
        dateEndPrevious: this.reportForm.value.dateRange.dateEndPrevious,
        department: changes.department === -1 ? null : changes.department,
        attendant: changes.attendant === -1 ? null : changes.attendant,
        channel: changes.channel === -1 ? null : changes.channel
      }

      this.getCountContactItems(formsValue);
    }
  }

  onPeriodChangeCurrent(event: any) {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 7)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 15)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 30)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.reportForm.get('dateRange').patchValue({
          dateStartCurrent: new Date(today.setDate(today.getDate() - 60)),
          dateEndCurrent: new Date()
        });
        this.picker.close();
        break;
    }
  }

  onPeriodChangePrevious(event: any) {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 7)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 15)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 30)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.reportForm.get('dateRange').patchValue({
          dateStartPrevious: new Date(today.setDate(today.getDate() - 60)),
          dateEndPrevious: new Date()
        });
        this.picker.close();
        break;
    }
  }


  getByUserAttendant() {
    this.userSerivce.findByAttendant().subscribe({
      next: (users: User[]) => {
        this.userAttendant = users;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getDepartmentByCompany() {
    this.departmentService.getList().subscribe({
      next: (dresponseDepart: Department[]) => {
        this.department = dresponseDepart;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getByAllChannel() {
    this.channelService.getByAllChannel().subscribe({
      next: (channel: Channel[]) => {
        this.channelAttendant = channel;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getCountContactItems(form) {
    this.reportService.getCountContactItems(form).subscribe({
      next: (reportCount: ResponseCountItemsContactTypes) => {
        this.countItemsContact = reportCount;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }
  exportFile() {}
  
}
