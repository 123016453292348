import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Importation, ImportationService, Pager, ImportationStatusTranslateEnum, ExportService } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormBuilder } from '@angular/forms';
import { saveAs } from 'file-saver';

@Component({
  selector: 'eport-list-importation-component',
  templateUrl: 'report-list-importation.component.html',
  styleUrl: './report-list-importation.component.scss'
})
export class ReportListImportationComponent extends AbstractComponent implements OnInit {

  pager: Pager<Importation> = new Pager<Importation>({ perPage: 10 });

  listObservable: Observable<Pager<Importation>>;
  private termoDaBusca: Subject<string> = new Subject<string>();
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;

  labelFilter: string = 'Situação';
  defaultValue: string = '';

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public importationService: ImportationService,
    public exportService: ExportService,
    private fb: FormBuilder,
  ) {
    super(injector);
    this.createForm();
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(val => {
      this.getList();
    });
    this.getList();
  }

  private createForm(): void {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    this.formGroup = this.formBuilder.group({
      status: [null],
      dateRange: this.fb.group({
        dateStart: [yesterday, []],
        dateEnd: [today, []],
      }),
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.importationService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Importation>) => {
      this.pager = pager;
    });
  }

  setValueSearch(term: string, label: string = 'Situação'): void {
    this.defaultValue = term;
    this.formGroup.get('status')?.setValue(term);
    this.labelFilter = label;
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  getList() {
    this.loadingContent = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    const status = this.formGroup.get('status').value;
    if (status) {
      this.searchParams['status'] = status;
    }
    const dateRange = this.formGroup.get('dateRange').value;
    if(dateRange?.dateStart) {
      this.searchParams['dateStart'] = dateRange.dateStart.getTime();
    }
    if(dateRange?.dateEnd) {
      this.searchParams['dateEnd'] = dateRange.dateEnd.getTime();
    }
    this.importationService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value
        this.setupObservableSearch();
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  getStatus(status: string) {
    return ImportationStatusTranslateEnum[status];
  }

  onPeriodChange(event: any) {
    const today = new Date();
    switch (event.value) {
      case '1':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '7':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 7)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '15':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 15)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '30':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 30)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
      case '60':
        this.formGroup.get('dateRange').patchValue({
          dateStart: new Date(today.setDate(today.getDate() - 60)),
          dateEnd: new Date()
        });
        this.picker.close();
        break;
    }
  }

  exportFile() {
    this.loadingContent = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    const status = this.formGroup.get('status').value;
    if (status) {
      this.searchParams['status'] = status;
    }
    const dateRange = this.formGroup.get('dateRange').value;
    if(dateRange?.dateStart) {
      this.searchParams['dateStart'] = dateRange.dateStart.getTime();
    }
    if(dateRange?.dateEnd) {
      this.searchParams['dateEnd'] = dateRange.dateEnd.getTime();
    }
    this.importationService.getAll(1, 99999, this.searchString, this.searchParams).subscribe({
      next: async (value) => {
        const bufferExport = await this.exportService.generateExcelImportation(value.list);
        const blob = new Blob([bufferExport], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        saveAs(blob, 'relatorio-importacao.xlsx');
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message),
    });
  }
}
