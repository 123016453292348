<div class="content-page">

    <div class="mb-4 flex justify-between items-center">
        <div>
            <h1 class="font-black text-[20px] text-black">Relatório {{ title }}</h1>
            <h6 class="text-zinc-400 font-light text-sm my-2">
                Busque filtro para consultar com mais detalhes os relatórios
            </h6>
        </div>
        <div>
            <button class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" (click)="exportFile()">
                <i class="ph ph-export text-[24px]"></i>
                Exportar
            </button>
        </div>
    </div>
    <hr />

    <div class="w-full flex flex-col">
        <div class="flex flex-1 flex-col">
            <div class="form-wrapper">
                <form [formGroup]="reportForm">
                    <div class="form-content">
                        <div class="field-group">
                            <div class="field-date">
                                <mat-form-field>
                                    <mat-label>Período mês atual</mat-label>
                                    <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
                                        <input matStartDate placeholder="Data inicial"
                                            formControlName="dateStartCurrent">
                                        <input matEndDate placeholder="Data final" formControlName="dateEndCurrent">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker (selectionChange)="onPeriodChangeCurrent($event)">
                                        <mat-datepicker-actions>
                                            <mat-form-field>
                                                <mat-label>Selecione</mat-label>
                                                <mat-select (selectionChange)="onPeriodChangeCurrent($event)">
                                                    <mat-option value="1">Hoje</mat-option>
                                                    <mat-option value="7">Últimos 7 dias</mat-option>
                                                    <mat-option value="15">Últimos 15 dias</mat-option>
                                                    <mat-option value="30">Últimos 30 dias</mat-option>
                                                    <mat-option value="60">Últimos 60 dias</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <button mat-raised-button matDatepickerApply class="btnOk">OK</button>
                                        </mat-datepicker-actions>
                                    </mat-date-range-picker>
                                </mat-form-field>
                            </div>
                            <div class="field-date">
                                <mat-form-field>
                                    <mat-label>Período mês anterior</mat-label>
                                    <mat-date-range-input [rangePicker]="pickerPrevious" formGroupName="dateRange">
                                        <input matStartDate placeholder="Data inicial"
                                            formControlName="dateStartPrevious">
                                        <input matEndDate placeholder="Data final" formControlName="dateEndPrevious">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matIconSuffix [for]="pickerPrevious"></mat-datepicker-toggle>
                                    <mat-date-range-picker #pickerPrevious
                                        (selectionChange)="onPeriodChangePrevious($event)">
                                        <mat-datepicker-actions>
                                            <mat-form-field>
                                                <mat-label>Selecione</mat-label>
                                                <mat-select (selectionChange)="onPeriodChangePrevious($event)">
                                                    <mat-option value="1">Hoje</mat-option>
                                                    <mat-option value="7">Últimos 7 dias</mat-option>
                                                    <mat-option value="15">Últimos 15 dias</mat-option>
                                                    <mat-option value="30">Últimos 30 dias</mat-option>
                                                    <mat-option value="60">Últimos 60 dias</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <button mat-raised-button matDatepickerApply class="btnOk">OK</button>
                                        </mat-datepicker-actions>
                                    </mat-date-range-picker>
                                </mat-form-field>
                            </div>

                            <div class="field">
                                <mat-form-field>
                                    <mat-label>Canal</mat-label>
                                    <mat-select formControlName="channel">
                                        <mat-option [value]="-1">Todos</mat-option>
                                        <mat-option *ngFor="let item of channelAttendant" [value]="item?._id">{{
                                            item.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="field">
                                <mat-form-field>
                                    <mat-label>Departamento</mat-label>
                                    <mat-select formControlName="department">
                                        <mat-option [value]="-1">Todos</mat-option>
                                        <mat-option *ngFor="let item of department" [value]="item?._id">{{ item.name
                                            }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="field">
                                <mat-form-field>
                                    <mat-label>Atendente</mat-label>
                                    <mat-select formControlName="attendant">
                                        <mat-option [value]="-1">Todos</mat-option>
                                        <mat-option *ngFor="let item of userAttendant" [value]="item?._id">{{ item.name
                                            }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
    </div>

    <div class="cards-container">
        <mat-card class="card-container" >
            <mat-card-header>
                <h2 class="title">Canais</h2>
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <span class="card-total">{{ countItemsContact?.totalChannels || 0 }}</span>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-container" >
            <mat-card-header>
                <h2 class="title">Departamentos</h2>
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <span class="card-total">{{ countItemsContact?.totalDepartments || 0 }}</span>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-container" >
            <mat-card-header>
                <h2 class="title">Atendentes</h2>
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <span class="card-total">{{ countItemsContact?.totalUsers || 0 }}</span>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-container" >
            <mat-card-header>
                <h2 class="title">Contatos (esse mês)</h2>
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <span class="card-total">{{ countItemsContact?.totalContactsCurrent || 0 }}</span>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="card-container" >
            <mat-card-header>
                <h2 class="title">Contatos (mês anterior)</h2>
            </mat-card-header>
            <mat-card-content>
                <div class="card-content">
                    <span class="card-total">{{ countItemsContact?.totalContactsPrevious || 0 }}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>